import { error } from "jquery";
import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { callGetApi } from "services/ApiCaller";
import { SPARE_PARTS_MASTER_URL_DATA_SVC } from "services/CONSTANTS";
import { API_SUCCESS } from "services/ResponseCode";
import { partsReqObj } from "../warehouseConstents";
import { ReadOnlyField } from "pages/common/ReadOnlyField";
import ImageViewer from "pages/common/utils/ImageViewer";
import { currencyFormatter } from "pages/common/utils/currencyFormatter";
import { isEmpty } from "pages/common/textUtilities";

const PartsDetailsModal = ({ show, hideModal, recordId, warehouse, handleSnack }) => {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({ ...partsReqObj });

  const isDecimal = (num) => {
    return num % 1 !== 0;
  };

  useEffect(() => {
    if (recordId) {
      setLoading(true);
      const rUrl = `${SPARE_PARTS_MASTER_URL_DATA_SVC}${recordId}`;
      callGetApi(
        null,
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            if (responseData?.legacyMaterial === "D_WH_01") {
              if (warehouse === "D_WH_01") {
                setRecordData({
                  ...responseData,
                  replacesPartNumber: isDecimal(Number(responseData["replacesPartNumber"]) / 2)
                    ? Number(Number(responseData["replacesPartNumber"]) + 1) / 2
                    : Number(responseData["replacesPartNumber"]) / 2,
                  modelGroupDescription: isDecimal(Number(responseData["modelGroupDescription"]) / 2)
                    ? Number(Number(responseData["modelGroupDescription"]) + 1) / 2
                    : Number(responseData["modelGroupDescription"]) / 2,
                  materialGroup: isDecimal(Number(responseData["materialGroup"]) / 2)
                    ? Number(Number(responseData["materialGroup"]) + 1) / 2
                    : Number(responseData["materialGroup"]) / 2,
                  materialGroupDescription: isDecimal(Number(responseData["materialGroupDescription"]) / 2)
                    ? Number(Number(responseData["materialGroupDescription"]) + 1) / 2
                    : Number(responseData["materialGroupDescription"]) / 2,
                  availability: isDecimal(Number(responseData["availability"]) / 2)
                    ? Number(Number(responseData["availability"]) + 1) / 2
                    : Number(responseData["availability"]) / 2,
                });
              } else {
                setRecordData({
                  ...responseData,
                  replacesPartNumber: isDecimal(Number(responseData["replacesPartNumber"]) / 2)
                    ? Number(Number(responseData["replacesPartNumber"]) - 1) / 2
                    : Number(responseData["replacesPartNumber"]) / 2,
                  modelGroupDescription: isDecimal(Number(responseData["modelGroupDescription"]) / 2)
                    ? Number(Number(responseData["modelGroupDescription"]) - 1) / 2
                    : Number(responseData["modelGroupDescription"]) / 2,
                  materialGroup: isDecimal(Number(responseData["materialGroup"]) / 2)
                    ? Number(Number(responseData["materialGroup"]) - 1) / 2
                    : Number(responseData["materialGroup"]) / 2,
                  materialGroupDescription: isDecimal(Number(responseData["materialGroupDescription"]) / 2)
                    ? Number(Number(responseData["materialGroupDescription"]) - 1) / 2
                    : Number(responseData["materialGroupDescription"]) / 2,
                  availability: isDecimal(Number(responseData["availability"]) / 2)
                    ? Number(Number(responseData["availability"]) - 1) / 2
                    : Number(responseData["availability"]) / 2,
                });
              }
            } else {
              setRecordData({ ...responseData });
            }
            setLoading(false);
          } else {
            setRecordData({ ...partsReqObj });
            setLoading(false);
          }
        },
        (error) => {
          setRecordData({ ...partsReqObj });
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  return (
    <Modal show={show} onHide={hideModal} size="lg" centered>
      <Modal.Body>
        <h4>Part Details</h4>
        <div className="card border px-3 py-2 mb-3">
          {/* <div className="d-flex align-items-center justify-content-between equipment-pagination">
            <h4 className="font-weight-600 mb-0">{!isEmpty(recordData.partDescription) && recordData.partDescription}</h4>
            <h4 className="text-red">
              <b>{currencyFormatter.format(recordData?.listPrice || 0)}</b>
            </h4>
          </div> */}
          <div className="row mt-2">
            <div className="col-md-9 col-sm-9">
              <div className="row">
                <ReadOnlyField className="col-md-4 col-sm-4" label="PART NUMBER" value={recordData?.partNumber} />
                <ReadOnlyField
                  className="col-md-4 col-sm-4"
                  label="DESCRIPTION"
                  value={`${recordData.partDescription} ${recordData.modelGroupDescription}`}
                />
                <ReadOnlyField className="col-md-4 col-sm-4" label="TYPE" value={recordData?.partType} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="MANUFACTURER" value={recordData?.manufacturer} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="MODEL" value={recordData?.model} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE" value={currencyFormatter.format(recordData?.listPrice || 0)} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="GROUP NUMBER" value={recordData?.groupNumber} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="PARTS GROUP" value={recordData?.partsGroup} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="BEC CODE" value={recordData?.becCode} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="BEC CODE DESCRIPTION" value={recordData?.becCodeDescription} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="SERIAL NUMBER (IF ANY)" value={recordData?.serialNo} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="STATUS" value={recordData?.status} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="AVAILABLE STOCKS" value={recordData?.availability} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="BLOCKED" value={recordData?.materialGroup} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="QUALITY INSPECTION" value={recordData?.materialGroupDescription} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="ON ORDER STOCK" value={recordData?.modelGroupDescription} />
                <ReadOnlyField className="col-md-4 col-sm-4" label="CONSIGNMENT STOCK" value={recordData?.replacesPartNumber} />
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <ImageViewer
                src={recordData?.sparepartImages && recordData?.sparepartImages.length > 0 ? recordData?.sparepartImages[0] || "" : ""}
                style={{ objectFit: "fill" }}
              />
            </div>
          </div>
        </div>
        <div className="row px-2" style={{ justifyContent: "right" }}>
          <button className="btn bg-primary text-white mx-2" onClick={hideModal}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PartsDetailsModal;
