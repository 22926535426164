import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { SHIPMENT_HEADER_MASTER_URL } from "services/CONSTANTS";

import { GRID_STYLE } from "pages/common/constants";
import CustomizedSnackbar from "shared/CustomSnackBar";
import AddUpdatePR from "./AddUpdatePR";
import Moment from "react-moment";

const PRMaster = () => {
  const [pageNo, setPageNo] = useState(1);
  const [perPageSize, setPerPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const [prRecords, setPrRecords] = useState([]);
  const [prRecordId, setPrRecordId] = useState(null);

  const [openPRModal, setOpenPRModal] = useState(false);

  const [applyFilter, setApplyFilter] = useState("all");

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getPrRecords();
  }, [pageNo, perPageSize]);

  // get STO records
  const getPrRecords = async () => {
    setLoading(true);
    const rUrl = `${SHIPMENT_HEADER_MASTER_URL}?pageNumber=${pageNo - 1}&pageSize=${perPageSize}`;
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPrRecords(responseData);
          setLoading(false);
        } else {
          setPrRecords([]);
          setLoading(false);
        }
      },
      (error) => {
        setPrRecords([]);
        setLoading(false);
      }
    );
  };

  // view the PR Details
  const handleEditPRDetails = (row) => {
    setPrRecordId(row.shipmentHeaderId);
    setOpenPRModal(true);
  };

  // create the New PR Details
  const handleCreatePR = () => {
    setPrRecordId(null);
    setOpenPRModal(true);
  };

  const columns = [
    {
      field: "returnNumber",
      headerName: "STO Number",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (row?.returnNumber ? "PR" + row["returnNumber"].substring(2) : "-"),
    },
    {
      field: "rmaType",
      headerName: "Order Number",
      flex: 1,
      width: 100,
    },
    {
      field: "rmaNumber",
      headerName: "Reference Id",
      flex: 1,
      width: 100,
    },
    {
      field: "senderLocation",
      headerName: "Sender Location",
      flex: 1,
      width: 100,
    },
    {
      field: "receiverLocation",
      headerName: "Receiver Location",
      flex: 1,
      width: 100,
    },
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      flex: 1,
      width: 100,
    },
    {
      field: "shippedOn",
      headerName: "Shipped On",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (row?.shippedOn ? <Moment format="DD/MM/YYYY">{row?.shippedOn}</Moment> : "-"),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleEditPRDetails(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div>
        <div className="content-body bg-white" style={{ minHeight: "884px" }}>
          <div className="container-fluid mt-3">
            <div className="d-flex justify-content-between align-items-baseline mt-4">
              <div>
                <h4 className="font-weight-600 mb-0 ">Purchase Requisition (PR)</h4>
                <div className="d-flex align-items-center mt-3">
                  <p className={`cursor ${applyFilter === "all" ? "active-filter-warehouse" : ""}`} onClick={() => setApplyFilter("all")}>
                    All PRs
                  </p>
                  <p className={`mx-3 cursor  ${applyFilter === "today" ? "active-filter-warehouse" : ""}`} onClick={() => setApplyFilter("today")}>
                    Today's PRs
                  </p>
                  <p className={`cursor  ${applyFilter === "lastWeek" ? "active-filter-warehouse" : ""}`} onClick={() => setApplyFilter("lastWeek")}>
                    Last Week's PRs
                  </p>
                </div>
              </div>
              <div>
                <button className="btn bg-primary text-white" onClick={handleCreatePR}>
                  Create PR
                </button>
              </div>
            </div>

            <div className="mb-4 mt-2">
              <DataGrid
                loading={loading}
                sx={GRID_STYLE}
                getRowId={(row) => row.shipmentHeaderId}
                // page={pageNo + 1}
                autoHeight
                // pageSize={perPageSize}
                // onPageChange={(newPage) => getStoRecords(newPage, perPageSize)}
                // onPageSizeChange={(newPageSize) => getStoRecords(pageNo, newPageSize)}
                rows={prRecords}
                columns={columns}
                rowsPerPageOptions={[10, 20, 50]}
                // paginationMode="server"
                // rowCount={totalRows}
              />
            </div>
          </div>
        </div>
      </div>

      {openPRModal && <AddUpdatePR show={openPRModal} hideModal={() => setOpenPRModal(false)} recordId={prRecordId} />}
    </>
  );
};

export default PRMaster;
