import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

// import ViewOrderProfile from "./InvoiceBillingHistory";
import DataGridContainer from "./common/DataGridContainer";
import InvoiceBillingHistory from "./InvoiceBillingHistory";
import Select from "react-select";
import { GRID_STYLE, selectCustomStyle } from "./common/constants";
import { callGetApi } from "../services/ApiCaller";
import { API_SUCCESS } from "../services/ResponseCode";
import { PARTS_ORDER_MASTER_URL, SEARCH_PARTS_ORDER_GET } from "../services/CONSTANTS";
import { currencyFormatter } from "./common/utils/currencyFormatter";
import Moment from "react-moment";
import OrderProgressModal from "./OrderProgressModal";

const OrderHistoryMaster = ({ auth, handleSnack }) => {
  const [viewPlacedOrder, setViewPlacedOrder] = useState(false);
  const [selectedValue, setSelectedValue] = useState({ label: "Parts", value: "PARTS" });
  const [partsOrders, setPartsOrders] = useState([]);
  const [partsOrderRecordCount, setPartsOrderRecordCount] = useState(10);
  const [partsPageno, setPartsPageno] = useState(0);
  const [partsPageSize, setPartsPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [orderId, setOrderId] = useState(null);
  const [cartId, setCartId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (selectedValue?.value === "PARTS") {
      getPartsOrder();
    }
  }, [selectedValue]);

  const getPartsOrder = () => {
    // const rUrl = SEARCH_PARTS_ORDER_GET + "?search=orderRequistion:true";
    const rUrl = PARTS_ORDER_MASTER_URL + "?pageNumber=0&pageSize=20";
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPartsOrderRecordCount(responseData.length);
          setPartsOrders(responseData);
        } else {
          setPartsOrders([]);
        }
      },
      (error) => {
        setPartsOrders([]);
      }
    );
  };

  const handleChangeSelectValue = (e) => {
    setSelectedValue(e);
  };

  // view the order details
  const handleViewOrderDetails = (row) => {
    // if (!row.cart) {
    //   handleSnack("error", "Something wrong.");
    //   return;
    // }
    setSelectedRow(row);
    setCartId(row.cart);
    setOrderId(row.partsOrderId);
    setViewPlacedOrder(true);
  };

  // close the Order details Modal
  const handleCloseOrderDetailsModal = () => {
    setViewPlacedOrder(false);
    setOrderId(null);
    setCartId(null);
  };

  const partsOrderColumn = [
    { field: "partsOrderName", headerName: "Order/Quote#", flex: 1, minWidth: 120, renderCell: ({ row }) => `#${row?.partOrderNumber}` },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      // minWidth: 120,
      renderCell: (params) => (
        <span
          className={`dispatched-order-status ${
            params.value.toLowerCase() === "reviewed"
              ? "dispatch-reviewed-order"
              : params.value.toLowerCase() === "in revision"
              ? "dispatch-revision-order"
              : "dispatch-approved-order"
          }`}
        >
          PAID
          {/* {params.value} */}
        </span>
      ),
    },
    {
      field: "serviceName",
      headerName: "Product/Service Name",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => currencyFormatter.format(row?.totalPrice),
    },
    {
      field: "quoteDate",
      headerName: "Order/Quote Date",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => <Moment format="DD/MM/YYYY">{row.createdAt}</Moment>,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => handleViewOrderDetails(row)}>
                <EditOutlinedIcon />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const dispatchData = [
    {
      id: 0,
      order: "QT000001",
      status: "Approved",
      quoteDate: "29-04-2023",
      price: "90000",
      serviceName: "EW6000",
    },
    {
      id: 1,
      order: "QT000002",
      status: "In Revision",
      quoteDate: "29-04-2023",
      price: "50020",
      serviceName: "EW6000",
    },
    {
      id: 2,
      order: "QT000003",
      status: "Reviewed",
      quoteDate: "29-04-2023",
      price: "50000",
      serviceName: "EW6000",
    },
  ];
  return (
    <>
      <div style={{ minHeight: "884px" }}>
        <h4 className="font-weight-600 mb-0 mt-4">Quotes </h4>
        <div className="my-4">
          <div className="d-flex  justify-content-between">
            <div className=" align-items-center m-0">
              <div className={`transaction-search-box d-flex align-items-center mr-3 my-2 border-radius-6`} style={{ position: "relative" }}>
                <div className="transaction-search customize">
                  <input
                    className="transaction-input-sleact pr-1"
                    type="text"
                    placeholder="Search Quotes"
                    // value={inputRegion}
                    autoComplete="off"
                    style={{ width: "300px" }}
                    // onChange={handleDropDownSearchForRegion}
                  />
                  <div
                    className="btn bg-primary text-white cursor"
                    // onClick={handleSearchForRegion}
                  >
                    <span className="mr-2">
                      <SearchIcon />
                    </span>
                    SEARCH
                  </div>
                  {
                    // <ul
                    //     className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                    //     id="style"
                    // >
                    //     {inputRegion.length !== 0 && searchedDropDownRegion.length === 0 &&
                    //         (
                    //             <li className="list-group-item">
                    //                 No
                    //                 Result
                    //                 found
                    //             </li>
                    //         )}
                    //     {inputRegion.length !== 0 && searchedDropDownRegion.length !== 0 && searchedDropDownRegion.map((currentItem, j) =>
                    //     (
                    //         <li
                    //             className="list-group-item"
                    //             key={j}
                    //             onClick={() =>
                    //                 handleSelectDropdownItemForRegion(
                    //                     currentItem
                    //                 )
                    //             }
                    //         >
                    //             {
                    //                 currentItem["city"]
                    //             }
                    //         </li>
                    //     )
                    //     )}
                    // </ul>
                  }
                </div>
              </div>
            </div>
            <div className="mr-3 my-2">
              <Select
                // className="customselect1"
                className="border-primary text-primary"
                id="custom"
                value={selectedValue}
                // placeholder=" + Create"
                styles={selectCustomStyle}
                options={[
                  { label: "Parts", value: "PARTS" },
                  { label: "Services", value: "SERVICES" },
                ]}
                onChange={handleChangeSelectValue}
              />
              {/* <button className="btn transparent">
                <FilterListIcon /> Filters
              </button> */}
            </div>
          </div>
        </div>
        <DataGrid
          loading={loading}
          rows={partsOrders}
          columns={partsOrderColumn}
          page={partsPageno}
          autoHeight
          pageSize={partsPageSize}
          sx={GRID_STYLE}
          onPageChange={(newPage) =>
            // handleClaimPaginationChange(newPage, pageSize)
            setPartsPageno(newPage)
          }
          onPageSizeChange={(newPageSize) => setPartsPageSize(newPageSize)}
          rowCount={partsOrderRecordCount}
          rowsPerPageOptions={[10, 20, 50]}
          // paginationMode="server"
          disableRowSelectionOnClick
          getRowId={(row) => row.partsOrderId}
        />
        {/* <DataGridContainer getRowId={(row) => row.claimId} rowsData={partsOrders} columns={partsOrderColumn} orderData={true} /> */}
      </div>
      {/* {viewPlacedOrder && (
        <InvoiceBillingHistory
          show={viewPlacedOrder}
          hideModal={() => setViewPlacedOrder(false)}
        />
      )} */}

      {viewPlacedOrder && (
        <OrderProgressModal
          show={viewPlacedOrder}
          hideModal={handleCloseOrderDetailsModal}
          handleSnack={handleSnack}
          orderId={orderId}
          cartId={cartId}
          rowData={selectedRow}
          currencyFormatter={currencyFormatter}
        />
      )}
    </>
  );
};

export default OrderHistoryMaster;
