import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import Cookies from "js-cookie";
import { RAZORPAY_ORDER_CREATE_POST } from "./CONSTANTS";

/* ----------------- Authorization ------------------- */

// get the Authorization AuthToken data
export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    // auth: {
    //   username: "rzp_test_j235P37vsA0vvx",
    //   password: "wFn0PCXuL1XfJxmL7CEatL9o",
    // },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}

/* ------------------------------------------------------------ */

// Create Rozer pay order //
export const createRozerPayOrder = (data) => {
  console.log("CartOrderServices > createRozerPayOrder called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${RAZORPAY_ORDER_CREATE_POST}`, data, getAccessToken())
        .then((res) => {
          console.log("CartOrderServices > createRozerPayOrder  response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("createRozerPayOrder > axios err=", err);
          reject("Error in createRozerPayOrder axios!");
        });
    } catch (error) {
      console.error("createRozerPayOrder general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};
