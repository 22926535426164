import React from "react";

import { Modal } from "react-bootstrap";
import { ReadOnlyField } from "../common/ReadOnlyField";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { callGetApi, callPostApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import {
  LOCAL_CASES_WORKLIST,
  LOCAL_CASES_WORKLIST_GET,
} from "services/CONSTANTS";
import Moment from "react-moment";

const RepairRequest = ({
  show,
  hideModal,
  handleSnack,
  equipmentDetails,
  warrantyDetails,
  customerDetails,
  yearlyWarrantyDetails,
}) => {
  
  const [recordObj, setRecordObj] = useState({
    caseId: 0,
    // caseUniqueId: "",
    // serviceRequestNumber: "",
    requester: customerDetails?.fullName,
    team: "WARRANTY_MANAGEMENT",
    requestType: "REPAIR_SERVICE",
    caseSource: "COMMERCE",
    title: "",
    task: "",
    startDate: new Date(),
    promiseDate: new Date(),
    status: "NEW",
    userName: "",
    assignedTo: "",
    make: equipmentDetails?.maker || "",
    model: equipmentDetails?.model || "",
    serialNumber: equipmentDetails?.makerSerialNumber || "",
    warrantyId: equipmentDetails?.warrantyId || 0,
  });

  // change input fields text
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecordObj({ ...recordObj, [name]: value });
  };

  const handleSubmit = () => {
    const rObj = { ...recordObj };
    const rUrl = LOCAL_CASES_WORKLIST;
    callPostApi(null, rUrl, rObj, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        handleSnack(
          "success",
          `Service request number ${responseData?.serviceRequestNumber} generated successfully`
        );
        hideModal();
      } else {
        handleSnack("info", "something went wrong");
      }
    });
  };
  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <h5>Request For Repair</h5>
        <div className="card border px-3">
          <div className=" mt-2 input-fields">
            <h5>Warranty Details</h5>
            <div className="card border px-3 py-2 mt-2">
              <div className="row mt-2">
                <ReadOnlyField
                  label="MODEL NUMBER"
                  value={warrantyDetails.modelNumber}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="EQUIPMENT NUMBER"
                  value={warrantyDetails.equipmentNumber}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="SERIAL NUMBER"
                  value={warrantyDetails.serialNumber}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="COMPONENT CODE"
                  value={warrantyDetails?.componentCode}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="WARRANTY END DATE"
                  value={yearlyWarrantyDetails.warrantyEndDate ? <Moment format="DD/MM/YYYY">{yearlyWarrantyDetails.warrantyEndDate}</Moment>:"NA"}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="WARRANTY END USAGE"
                  value={yearlyWarrantyDetails.warrantyEndUsage}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="INSTALLED ON"
                  value={equipmentDetails.installationDate ? <Moment format="DD/MM/YYYY">{equipmentDetails.installationDate}</Moment>:"NA"}
                  className="col-md-3 col-sm-3"
                />
              </div>
            </div>
            <h5>Customer Details</h5>
            <div className="card border px-3 py-2 mt-2">
              <div className="row mt-2">
                <ReadOnlyField
                  label="CUSTOMER ID"
                  value={customerDetails?.customerId}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="CUSTOMER NAME"
                  value={customerDetails?.customerName}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="EMAIL"
                  value={customerDetails?.email}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="ADDRESS"
                  value={customerDetails?.address}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="CITY"
                  value={customerDetails?.city}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="STATE"
                  value={customerDetails?.state}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="COUNTRY"
                  value={customerDetails?.country}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="ZIPCODE"
                  value={customerDetails?.zipCode}
                  className="col-md-3 col-sm-3"
                />
                <ReadOnlyField
                  label="PHONE"
                  value={customerDetails?.phoneNumber}
                  className="col-md-3 col-sm-3"
                />
              </div>
            </div>
            <h5>Request</h5>

            <div className="card border px-3 py-2 mt-2">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    REASON FOR REQUEST
                  </label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="requestType"
                    name="requestType"
                    placeholder="Reason for Request"
                    value={"REPAIR SERVICE"}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    DESCRIPTION (SHORT DESCRIPTION)
                  </label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="title"
                    name="title"
                    placeholder="Short Description"
                    value={recordObj?.title}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    BRIEF DESCRIPTION (LONG DESCRIPTION)
                  </label>
                  <textarea
                    className="form-control border-radius-10 text-primary"
                    id="task"
                    name="task"
                    placeholder="Brief Description"
                    cols={30}
                    rows={4}
                    value={recordObj?.task}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-0" style={{ justifyContent: "right" }}>
          <button
            className="btn btn-primary text-white mx-2"
            onClick={handleSubmit}
          >
            Save & Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RepairRequest;
