import React, { useState } from "react";

import { Divider } from "@mui/material";

import Select from "react-select";

import AddUpdatePR from "../PR/AddUpdatePR";
import AllocateStock from "./AllocateStock";
import InventoryParts from "./InventoryParts";
import AddUpdateSTO from "../STO/AddUpdateSTO";

import InventoryConsumables from "./InventoryConsumables";

import { inventoryOptions } from "../warehouseConstents";
import { FONT_STYLE_SELECT } from "pages/common/constants";
import CustomizedSnackbar from "shared/CustomSnackBar";

const InventoryMaster = () => {
  const [selectedValue, setSelectedValue] = useState({ label: "Parts", value: "PARTS" });

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div>
        <div className="content-body bg-white" style={{ minHeight: "884px" }}>
          <div className="container-fluid mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="font-weight-600 mb-0 mt-4">Inventory</h4>
              <Select
                options={inventoryOptions}
                styles={{
                  ...FONT_STYLE_SELECT,
                  container: (provided) => ({
                    ...provided,
                    width: 150,
                  }),
                }}
                // sx={{ width: "500px" }}
                value={selectedValue}
                onChange={(e) => setSelectedValue(e)}
              />
            </div>
            <Divider sx={{ marginY: 2 }} />
            {selectedValue?.value === "PARTS" && <InventoryParts handleSnack={handleSnack} />}
            {selectedValue?.value === "CONSUMABLES" && <InventoryConsumables handleSnack={handleSnack} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryMaster;
