import React, { useEffect, useState } from "react";

import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import { currencyFormatter } from "pages/common/utils/currencyFormatter";
import { Tooltip } from "@mui/material";

const PaymentSuccess = ({ show, hideModal, handleSnack, cartItems, labourCharge, handleStepperIncrease }) => {
  const [timeLeft, setTimeLeft] = useState(7); // 7 seconds countdown
  const [paymentId, setPaymentId] = useState(null);

  useEffect(() => {
    generatePaymentId();
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      hideModal();
      handleStepperIncrease()
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clear the timer on component unmount
    return () => clearInterval(timer);
  }, [timeLeft]);

  // generate random payment id
  const generatePaymentId = () => {
    if (!paymentId) {
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      const randomId = "pay_" + array[0].toString(36) + Date.now();
      setPaymentId(randomId);
    }
  };

  // copy payment id
  const hadnleCopyPaymentId = () => {
    navigator.clipboard.writeText(paymentId);
    handleSnack("success", "payment id copied successfully.");
  };

  return (
    <>
      <Modal show={show} size={"lg"} dialogClassName="payment-success-modal-content" centered>
        <Modal.Body style={{ backgroundColor: "#009E5C", border: "none" }}>
          <div className="text-center align-items-center text-white mt-3">
            <p className="font-size-15 font-weight-400 mb-1">You will be redirect in {timeLeft} Seconds</p>
            <h4 className="text-white font-size-21 font-weight-500">Payment Successful</h4>
          </div>

          <div className="text-center my-5">
            <CheckCircleOutlineRoundedIcon sx={{ color: "#75FE51", fontSize: 100 }} />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="card px-3 py-4">
              <div className="d-flex align-items-center">
                <div>
                  <h5 className="mb-0 text-truncate font-weight-500 w-75">Auxentious Technologies Pvt. Ltd.</h5>
                  <Moment format="MMM DD, YYYY, hh:mm A">{new Date()}</Moment>
                </div>
                <h3>
                  {cartItems &&
                    cartItems["parts"].length !== 0 &&
                    currencyFormatter.format(
                      cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) +
                        (cartItems["parts"].reduce((sum, cartItem) => {
                          return sum + cartItem?.listPrice * cartItem?.quantity;
                        }, 0) *
                          10) /
                          100 -
                        (cartItems["parts"].reduce((sum, cartItem) => {
                          return sum + cartItem?.listPrice * cartItem?.quantity;
                        }, 0) *
                          2) /
                          100 +
                        labourCharge
                    )}
                </h3>
              </div>
              <div className="d-flex align-items-center mt-2">
                <span className="">Card</span>
                <span className="mx-2" style={{ height: "18px", border: "1px solid #3d3939b8" }}></span>
                <div className="d-flex align-items-baseline">
                  <span className="font-size-13 text-black">{paymentId}</span>
                  <span className="ml-2 cursor" onClick={hadnleCopyPaymentId}>
                    <Tooltip title="Copy">
                      <ContentCopyRoundedIcon sx={{}} />
                    </Tooltip>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center mt-1">
                <span>
                  <ErrorOutlineRoundedIcon /> Visit
                  <a className="text-black cursor mx-1" href="https://razorpay.com/support">
                    razorpay.com/support
                  </a>
                  for quries
                </span>
              </div>
            </div>
          </div>
          <div className="text-center text-white font-size-12 mt-3">
            Secured by <b className="font-size-14">Razorpay</b>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentSuccess;
