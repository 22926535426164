import React, { useState } from "react";

import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { currencyFormatter } from "pages/common/utils/currencyFormatter";
import DataTable from "react-data-table-component";
import { defaultPartsRecord, expendTableCustomStyle } from "../warehouseConstents";
import LoadingProgress from "pages/common/Loader";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const InventoryConsumables = ({ handleSnack }) => {
  const [tabValue, setTabValue] = useState("inventory");

  const [expandedPartRowId, setExpandedPartRowId] = useState(null);
  const [partPageNo, setPartPageNo] = useState(1);
  const [partsPerPage, setPartsPerPage] = useState(5);
  const [partsTotalRows, setPartsTotalRows] = useState(5);
  const [partsLoading, setPartsLoading] = useState(false);

  const [wareHousePartPageNo, setWareHousePartPageNo] = useState(1);
  const [wareHousePartPerPage, setWareHousePartPerPage] = useState(5);
  const [wareHousePartsTotalRows, setWareHousePartsTotalRows] = useState(0);
  const [wareHouserPartsLoading, setWareHouserPartsLoading] = useState(false);

  const [orderStockPageNo, setOrderStockPageNo] = useState(1);
  const [orderStockPerPage, setOrderStockPerPage] = useState(5);
  const [orderStockTotalRows, setOrderStockTotalRows] = useState(0);
  const [orderStockLoading, setOrderStockLoading] = useState(false);

  const [partStockWareHouses, setPartStockWareHouses] = useState([]);

  const partsColumns = [
    {
      id: "partNumber",
      name: "Part Number",
      selector: (row) => row.partNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partNumber || "-",
    },
    {
      id: "partDescription",
      name: "Part Description",
      selector: (row) => row.partDescription,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partDescription || "-",
    },
    {
      id: "partType",
      name: "Type",
      selector: (row) => row.partType,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partType || "-",
    },
    {
      id: "partsGroup",
      name: "Part Group",
      selector: (row) => row.partsGroup,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partsGroup || "-",
    },
    {
      id: "model",
      name: "Model",
      selector: (row) => row.model,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.model || "-",
    },
    {
      id: "stockQuantity",
      name: "Total Stock",
      selector: (row) => row.stockQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.stockQuantity || "0",
    },
    {
      id: "stockStatus",
      name: "Status",
      selector: (row) => row.stockStatus,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.stockStatus || "",
    },
  ];

  const partWareHouseStockColumns = [
    {
      id: "partNumber",
      name: "Part Number",
      selector: (row) => row.partNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partNumber || "-",
    },
    {
      id: "partDescription",
      name: "Part Description",
      selector: (row) => row.partDescription,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partDescription || "-",
    },
    {
      id: "wareHouseNumber",
      name: "Plant/Warehouse Number",
      selector: (row) => row.wareHouseNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.wareHouseNumber || "-",
    },
    {
      id: "stockQuantity",
      name: "Stock Quantity",
      selector: (row) => row.stockQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.stockQuantity || "-",
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.status || "-",
    },
    {
      id: "action",
      name: "Action",
      selector: (row) => row.status,
      wrap: true,
      minWidth: 200,
      cell: (row) => (
        <div>
          <button className="btn border-primary text-primary p-2">Create STO</button>
        </div>
      ),
    },
  ];

  const orderStockColumns = [
    {
      id: "partNumber",
      name: "Part Number",
      selector: (row) => row.partNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partNumber || "-",
    },
    {
      id: "partDescription",
      name: "Description",
      selector: (row) => row.partDescription,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partDescription || "-",
    },
    {
      id: "createdDate",
      name: "Date",
      selector: (row) => row.createdDate,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.createdDate || "-",
    },
    {
      id: "quantity",
      name: "Quantity",
      selector: (row) => row.quantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.quantity || "-",
    },
    {
      id: "fromPlant",
      name: "From Plant",
      selector: (row) => row.fromPlant,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.fromPlant || "-",
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.status || "-",
    },
    {
      id: "itemReceived",
      name: "Item received",
      selector: (row) => row.itemReceived,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.itemReceived || "-",
    },
    {
      id: "sendEmail",
      name: "Send Email",
      selector: (row) => row.sendEmail,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.sendEmail || "-",
    },
    {
      id: "comments",
      name: "Comments",
      selector: (row) => row.comments,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.comments || "-",
    },
    {
      id: "action",
      name: "Action",
      selector: (row) => row.destPlant,
      wrap: true,
      minWidth: 200,
      cell: (row) => row.destPlant || "-",
    },
  ];

  const fetchPartsStockItems = (isExpanded, row) => {
    if (isExpanded) {
      try {
        //   setComponentLoading(true);
        // setExpandedPartRowId(null);
        setWareHousePartsTotalRows(row.wareHouseStocks.length);
        setPartStockWareHouses(row.wareHouseStocks);
        setExpandedPartRowId(row.id);
        // console.log(comp)
      } catch (error) {
        console.error("Error fetching components:", error);
      } finally {
        //   setComponentLoading(false);
      }
    } else {
      setExpandedPartRowId(null);
    }
  };

  const expendablePartStockRow = (row) => {
    return (
      <div className="p-3 border-bottom">
        <div className="border border-radius-10">
          <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex align-items-center">
              <h6 className="mb-0 font-weight-600 font-size-14 mr-3">Stocks Stores</h6>
            </div>
          </div>
          {/* {partStockWareHouses.length !== 0 ? (
              <> */}
          <DataTable
            data={partStockWareHouses}
            columns={partWareHouseStockColumns}
            customStyles={expendTableCustomStyle}
            paginationPerPage={wareHousePartPerPage}
            onChangePage={(e) => setWareHousePartPageNo(e)}
            onChangeRowsPerPage={(e) => {
              setWareHousePartPageNo(1);
              setWareHousePartPerPage(e);
            }}
            paginationTotalRows={wareHousePartsTotalRows}
            paginationRowsPerPageOptions={[5, 10]}
            paginationDefaultPage={wareHousePartPageNo}
            progressPending={wareHouserPartsLoading}
            progressComponent={<LoadingProgress />}
            persistTableHead
            pagination
            paginationServer
          />
          {/* </>
            ) : (
              <div className="text-center pb-2">
                <h4 className="text-muted">No Parts Available in Any Warehouse</h4>
              </div>
            )} */}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row my-5">
        <div className="col-md-2 col-sm-2 border-right pr-5">
          <div className="d-flex">
            <MonetizationOnOutlinedIcon className="font-size-32 text-primary mt-1" />
            <div className="mx-2">
              <span className="font-size-13 font-weight-500">TOTAL ASSET VALUE</span>
              <h3>{currencyFormatter.format(10200323)}</h3>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 pl-5">
          <div className="d-flex align-items-center">
            <h3>32</h3>
            <span className="font-size-13 font-weight-500 mx-2">PRODUCT</span>
          </div>
          <div className="d-flex justify-content-evenly">
            <div className="inventory-products-line  border-radius-10 bg-green" style={{ width: "50%" }}></div>
            <div className="inventory-products-line border-radius-10 mx-2 bg-yellow" style={{ width: "20%" }}></div>
            <div className="inventory-products-line border-radius-10 bg-red" style={{ width: "30%" }}></div>
          </div>
          <div className="d-flex justify-content-evenly mt-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ borderRadius: 10, content: '" "', display: "block", marginRight: 5, height: 10, width: 10, backgroundColor: "#00b8b0" }}
                ></span>
                <span className="font-size-13">In Stock:</span>
              </div>
              <span className="font-size-14 font-weight-500 mx-2">20</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mx-3">
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ borderRadius: 10, content: '" "', display: "block", marginRight: 5, height: 10, width: 10, backgroundColor: "#d8bd5f" }}
                ></span>
                <span className="font-size-13">Low Stock:</span>
              </div>
              <span className="font-size-14 font-weight-500 mx-2">4</span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ borderRadius: 10, content: '" "', display: "block", marginRight: 5, height: 10, width: 10, backgroundColor: "#c14e56" }}
                ></span>
                <span className="font-size-13">Out of Stock:</span>
              </div>
              <span className="font-size-14 font-weight-500 mx-2">8</span>
            </div>
          </div>
        </div>
      </div>
      <Box className="mt-0" sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 3 }}>
            <TabList className="" onChange={(e, type) => setTabValue(type)}>
              <Tab label="Inventory" value={"inventory"} className="heading-tabs" />
              <Tab label="Order Stock" value={"orderStock"} className="heading-tabs" />
            </TabList>
          </Box>
          <TabPanel value="inventory">
            <>
              <div className="row my-3">
                <div className="col-md-5 col-sm-5">
                  <div className="customselectsearch">
                    <div className={`form-control-search`}>
                      <span class="fa fa-search fa-lg " />
                    </div>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      placeholder="Search Consumables"
                      // value={props.value}
                      autoComplete="off"
                      // onChange={props.onChange}
                      // disabled={props.disabled}
                      // placeholder={props.placeholder || ""}
                    />
                  </div>
                </div>
              </div>
              <DataTable
                data={defaultPartsRecord}
                columns={partsColumns}
                customStyles={expendTableCustomStyle}
                expandableRows={true}
                expandableRowsComponent={(itemData) => expendablePartStockRow(itemData.data, true)}
                expandableRowExpanded={(row) => row.id === expandedPartRowId}
                onRowExpandToggled={fetchPartsStockItems}
                paginationPerPage={partsPerPage}
                onChangePage={(e) => setPartPageNo(e)}
                onChangeRowsPerPage={(e) => {
                  setPartPageNo(1);
                  setPartsPerPage(e);
                }}
                paginationTotalRows={partsTotalRows}
                paginationRowsPerPageOptions={[5, 10]}
                paginationDefaultPage={partPageNo}
                progressPending={partsLoading}
                progressComponent={<LoadingProgress />}
                persistTableHead
                pagination
                paginationServer
              />
            </>
          </TabPanel>
          <TabPanel value="orderStock">
            <DataTable
              data={[]}
              columns={orderStockColumns}
              customStyles={expendTableCustomStyle}
              paginationPerPage={orderStockPerPage}
              onChangePage={(e) => setOrderStockPageNo(e)}
              onChangeRowsPerPage={(e) => {
                setOrderStockPageNo(1);
                setOrderStockPerPage(e);
              }}
              paginationTotalRows={orderStockTotalRows}
              paginationRowsPerPageOptions={[5, 10]}
              paginationDefaultPage={orderStockPageNo}
              progressPending={orderStockLoading}
              progressComponent={<LoadingProgress />}
              persistTableHead
              pagination
              paginationServer
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default InventoryConsumables;
