import React, { useEffect, useState } from "react";

import { Divider } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VerifiedIcon from "@mui/icons-material/Verified";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { Modal } from "react-bootstrap";
import Moment from "react-moment";

import {
  customerRequestObj,
  equipmentRequestObj,
  installerRequestObj,
  installerTypeOptions,
  warrantyBasisOptions,
  warrantyCategoryOptions,
  warrantyStatusOptions,
  warrantyTypeOptions,
  warrantyUnitOptions,
  yearlyWarrantyObj,
} from "./warrantyManagement/warrantyManagementConstants";
import { fetchWarranty } from "../services/EquipmentService";
import {
  Get_Equipment_Datails_By_Id_GET,
  SEARCH_CUSTOMER,
  WARRANTY_INSTALLER_MASTER_URL,
  Warranty_Yearly_GetById_GET,
} from "../services/CONSTANTS";
import { API_SUCCESS } from "../services/ResponseCode";
import { callGetApi } from "../services/ApiCaller";
import WarrantyClaimAddUpdate from "./WrrantyClaimAddUpdate";
import RepairRequest from "./machinesPossession/RepairRequest";
import LoadingProgress from "./common/Loader";
import EqupWarrantyContFilesModal from "./EqupWarrantyContFilesModal";
import UploadFilesModal from "./warrantyManagement/UploadFilesModal";
import EqupWarrantyViewFileModal from "./EqupWarrantyViewFileModal";

const defautFiles = [
  {
    id: 1,
    fileName: "certificate.png",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "CERTIFICATE",
    category: "CERTIFICATE",
  },
  {
    id: 2,
    fileName: "faultyPannel.png",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "FAULTY",
    category: "FAULTY",
  },
];

const CustomerWarrantyModal = ({
  show,
  hideModal,
  recordId,
  selectedRowDesc = "",
  warranty,
  setWarranty,
  handleSnack,
  handleShowWarrantyDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [warrantyRecord, setWarrantyRecord] = useState({
    ...warranty,
  });

  const [yearlyWarrantyRecord, setYearlyWarrantyRecord] = useState({
    ...yearlyWarrantyObj,
  });

  const [equipmentRecordData, setEquipmentRecordData] = useState({
    ...equipmentRequestObj,
  });

  const [customerRecord, setCustomerRecord] = useState({
    ...customerRequestObj,
  });

  const [installerRecord, setInstallerRecord] = useState({
    ...installerRequestObj,
  });

  const [showRaiseClaimModal, setShowRaiseClaimModal] = useState(false);
  const [openRepairRequestModal, setOpenRepairRequestModal] = useState(false);

  const [openAddProodModal, setOpenAddProodModal] = useState(false);
  const [filesRecords, setFilesRecords] = useState([...defautFiles]);
  const [fileType, setFileType] = useState("");
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openViewFileModal, setOpenViewFileModal] = useState(false);

  useEffect(() => {
    if (recordId && recordId !== null) {
      setLoading(true);
      fetchWarranty(recordId)
        .then((warranty) => {
          setWarranty(warranty);
          const { installerDetails, customerDetails, ...responseData } =
            warranty;

          // get category key value pairs
          const _category = warrantyCategoryOptions.find(
            (obj) => obj.value === responseData.category
          );

          // get basis key value pairs
          const _basis = warrantyBasisOptions.find(
            (obj) => obj.value === responseData.basis
          );

          // get unit key value pairs
          const _unit = warrantyUnitOptions.find(
            (obj) => obj.value === responseData.unit
          );

          // get status key value pairs
          const _warrantyStatus = warrantyStatusOptions.find(
            (obj) => obj.value === responseData.warrantyStatus
          );

          // set Overview record
          setWarrantyRecord({
            ...responseData,
            category: _category || "",
            basis: _basis || "",
            unit: _unit || "",
            warrantyStatus: _warrantyStatus || "",
          });

          // get equipment details
          if (responseData.equipmentId) {
            getEquipmentDetails(responseData.equipmentId);
          }

          if (responseData.installerId) {
            getInstallerDetails(responseData.installerId);
          }

          if (responseData.yearlyWarrantyIds.length !== 0) {
            getYearlyWarrantyDetails(responseData["yearlyWarrantyIds"][0]);
          }

          // setCustomerRecord({ ...customerDetails });
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [recordId]);

  // get equipment details
  const getEquipmentDetails = (id) => {
    // const rUrl = `${WARRANTY_EQUIPMENT_MASTER_URL}/${id}`;
    const rUrl = `${Get_Equipment_Datails_By_Id_GET}${id}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        setEquipmentRecordData({ ...responseData });

        if (responseData.currentClient) {
          getCustomerDetails(responseData.currentClient, "customerId");
        } else if (responseData.customer) {
          getCustomerDetails(responseData.customer, "fullName");
        }
      }
    });
  };

  // get warranty installer details
  const getInstallerDetails = (installerId) => {
    const rUrl = `${WARRANTY_INSTALLER_MASTER_URL}/${installerId}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        // set installer record data
        const _installerType = installerTypeOptions.find(
          (obj) => obj.value === responseData.installerType
        );
        setInstallerRecord({
          ...responseData,
          installerType: _installerType || "",
        });
      }
    });
  };

  // get yearly warranty details
  const getYearlyWarrantyDetails = (yearId) => {
    const rUrl = `${Warranty_Yearly_GetById_GET}/${yearId}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        // get basis key value pairs
        const _basis = warrantyBasisOptions.find(
          (obj) => obj.value === responseData.basis
        );

        const _warrantyType = warrantyTypeOptions.find(
          (obj) => obj.value === responseData.warrantyType
        );
        setYearlyWarrantyRecord({
          ...responseData,
          warrantyType: _warrantyType,
          basis: _basis,
        });
      }
    });
  };

  // get customer details
  const getCustomerDetails = (id, fieldName) => {
    const rUrl = SEARCH_CUSTOMER(`${fieldName}:${id}`);
    // const rUrl = SEARCH_CUSTOMER(`customerId:${id}`);
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        setCustomerRecord({
          customerId: responseData[0].customerId,
          customerName: responseData[0].fullName,
          email: responseData[0].email,
          address: responseData[0].addressDTO?.fullAddress,
          city: responseData[0].addressDTO?.district,
          state: responseData[0].addressDTO?.regionOrState,
          country: responseData[0].addressDTO?.country,
          zipCode: responseData[0].addressDTO?.zipCode,
          phoneNumber: responseData[0]?.phoneNumber,
        });
      }
    });
  };

  // open new File Upload Modal
  const handleShowFileUploadModal = () => {
    setOpenAddProodModal(!openAddProodModal);
    setOpenFileUploadModal(!openFileUploadModal);
  };

  // open new File Upload Modal
  const handleViewFileImage = () => {
    setOpenAddProodModal(!openAddProodModal);
    setOpenViewFileModal(!openViewFileModal);
  };

  return (
    <div>
      {showRaiseClaimModal && (
        <WarrantyClaimAddUpdate
          show={showRaiseClaimModal}
          hideModal={() => setShowRaiseClaimModal(false)}
          warranty={warranty}
          handleSnack={handleSnack}
          warrantyRecord={{ ...warrantyRecord, ...yearlyWarrantyRecord }}
          customerRecord={customerRecord}
          installerRecord={installerRecord}
        />
      )}

      {openRepairRequestModal && (
        <RepairRequest
          show={openRepairRequestModal}
          hideModal={() => setOpenRepairRequestModal(false)}
          handleSnack={handleSnack}
          equipmentDetails={equipmentRecordData}
          warrantyDetails={warrantyRecord}
          customerDetails={customerRecord}
          yearlyWarrantyDetails={yearlyWarrantyRecord}
        />
      )}

      {openAddProodModal && (
        <EqupWarrantyContFilesModal
          show={openAddProodModal}
          hideModal={() => setOpenAddProodModal(false)}
          handleSnack={handleSnack}
          filesRecords={filesRecords}
          setFilesRecords={setFilesRecords}
          handleShowFileUploadModal={handleShowFileUploadModal}
          setFileType={setFileType}
          handleViewFileModal={handleViewFileImage}
        />
      )}
      {openFileUploadModal && (
        <UploadFilesModal
          show={openFileUploadModal}
          hideModal={handleShowFileUploadModal}
          handleSnack={handleSnack}
        />
      )}

      {openViewFileModal && (
        <EqupWarrantyViewFileModal
          show={openViewFileModal}
          hideModal={handleViewFileImage}
          fileType={fileType}
        />
      )}

      <Modal
        show={show}
        onHide={hideModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title> Warranty/Contract Summary</Modal.Title>
          {loading ? (
            <LoadingProgress />
          ) : (
            <div className="card border mt-2">
              <div className="row mt-3 px-3">
                <div className="col-md-10 col-sm-10">
                  <h5>{warrantyRecord?.make || "NA"}</h5>
                  <div className="font-weight-bold text-primary">
                    {selectedRowDesc}
                  </div>
                  {/* <div className="font-weight-bold text-primary">CARGADOR RUEDAS/PORTAH. INTEGR - {warrantyRecord?.modelNumber}</div> */}
                  <p>Serial No. - {warrantyRecord?.serialNumber || "NA"}</p>
                  <p className="d-flex align-items-center font-size-14 font-weight-600 text-info">
                    <AttachFileIcon
                      className="font-size-36 mr-2 cursor"
                      onClick={() => setOpenAddProodModal(true)}
                    />
                    <span
                      className="cursor"
                      onClick={() => setOpenAddProodModal(true)}
                    >
                      {" "}
                      Add Proof For Purchase
                    </span>
                  </p>
                </div>
                <div className="col-md-2 col-sm-2">
                  <img
                    src="../assets/images/partCartImage.png"
                    alt="jcb"
                    className=" img-fluid w-75 "
                  />
                </div>
              </div>
              <Divider />
              <div>
                <div className="d-flex px-3 mt-3">
                  <VerifiedIcon style={{ fontSize: "60px", color: "green" }} />
                  <span className="ml-2">
                    <div className="text-light-dark font-size-14 font-weight-600">
                      Your Manufracture's Warranty
                    </div>
                    <div className="text-muted">
                      Warranty Ending On -{" "}
                      {yearlyWarrantyRecord?.warrantyEndDate ? (
                        <Moment format="DD MMMM YYYY">
                          {yearlyWarrantyRecord?.warrantyEndDate}
                        </Moment>
                      ) : (
                        "NA"
                      )}
                    </div>
                  </span>
                </div>
                <div className="d-flex align-items-center px-4 my-3 font-size-14 font-weight-600 text-info">
                  <span className="cursor" onClick={handleShowWarrantyDetails}>
                    See more details
                  </span>
                  <i
                    class="fa fa-solid fa-chevron-right ml-2 cursor"
                    onClick={handleShowWarrantyDetails}
                  ></i>
                </div>
              </div>
              <Divider />
              <div className="d-flex align-items-center justify-content-between px-3 mt-3">
                <div className="text-light-dark font-size-14 font-weight-500">
                  Extend Coverage
                  <span className="border border-primary px-2 ml-2 rounded">
                    Premier
                  </span>
                </div>
                <div className="btn bg-primary text-white">+ EXTEND</div>
              </div>
              <div className="d-flex align-items-center px-3 mb-3 font-size-14 font-weight-600 text-info">
                <span className="cursor" onClick={handleShowWarrantyDetails}>
                  See more details
                </span>
                <i
                  class="fa fa-solid fa-chevron-right ml-2 cursor"
                  onClick={handleShowWarrantyDetails}
                ></i>
              </div>
              <Divider />
              <div className="d-flex align-items-center justify-content-between px-3 my-3">
                <div className="d-flex">
                  <div
                    className="border border-primary px-2 cursor rounded text-primary font-size-14 font-weight-600 py-2 px-3"
                    onClick={() => setShowRaiseClaimModal(true)}
                  >
                    <i class=" fa fa-solid fa-life-ring mr-2"></i> RAISE CLAIM
                  </div>
                  <div
                    className="border border-primary px-2 ml-2 cursor rounded text-primary font-size-14 font-weight-600 py-2 px-3"
                    onClick={() => setOpenRepairRequestModal(true)}
                  >
                    <i class=" fa fa-solid fa-wrench mr-2"></i>
                    REQUEST FOR REPAIR
                  </div>
                </div>
                {/* <MoreHorizIcon className="text-primary" /> */}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerWarrantyModal;
